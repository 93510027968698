const submitButton = document.querySelector('.submit-form-button');
const noFileSelectedText = 'No file selected';
if(submitButton) {
  const formStatusMessageDOM = document.querySelector('.form_status_message');
  submitButton.addEventListener('click', function() {
    sendFormData(formStatusMessageDOM, submitButton);
  });
}

function sendFormData(formStatusMessageDOM, submitButton) {
    submitButton.setAttribute('disabled', true);
    
    const XHR = new XMLHttpRequest();
    const form = document.getElementById('we-custom-form');
    const formData = new FormData(form);
    
    XHR.responseType = 'json';
    XHR.onreadystatechange = function() {
        const {readyState, status, response, statusText} = XHR;
        if (readyState === 4) {
            const {data, success} = response;
            if(status === 200) {
                const {message, errors} = data;
                if(success) {
                    form.reset();
                    clearSelectedFile();
                    handleFormStatusMessage(success, formStatusMessageDOM, message);
                    hideErrorMessages();
                    afterRequestActions(submitButton);
                } else {
                    handleFormStatusMessage(success, formStatusMessageDOM, message);
                    afterRequestActions(submitButton);
                    showErrorMessages(errors);
                }
            } else {
                console.error(`Request failed: ${statusText}`);
            }
        }
    }
    XHR.open('POST', we_load_js.ajaxUrl);
    XHR.send(formData);
}

function handleFormStatusMessage(status, messageDomElement, message) {
    messageDomElement.style.display = 'block';
    messageDomElement.innerHTML = message;
    if(status) {
        messageDomElement.classList.remove('form-status-error');
        messageDomElement.classList.add('form-status-success');
    } else {
        messageDomElement.classList.remove('form-status-success');
        messageDomElement.classList.add('form-status-error');
    }
}

function showErrorMessages(errors) {
    hideErrorMessages();
    if(errors) {
        Object.keys(errors).forEach(key => {
            const field = document.querySelector(`.error-${key}`);
            field.parentNode.classList.add('has-error');
            field.style.display = 'inline-block';
            field.innerHTML = errors[key];
        });
    }
}

function hideErrorMessages() {
    const errorsMessages = document.querySelectorAll('.form-error-msg');
    errorsMessages.forEach(errorMessage => {
        errorMessage.style.display = 'none';
        errorMessage.innerHTML = '';
        errorMessage.parentNode.classList.remove('has-error')
    });
}

function reloadCaptcha() {
    grecaptcha.execute(we_load_js.captchaSiteKey, {action: 'contact_form'}).then(function(token) {
        var captcha_field = document.querySelector('#captcha_token');
        captcha_field.value = token;
    });
}

function afterRequestActions(submitDOM) {
    reloadCaptcha();
    submitDOM.removeAttribute('disabled');
}

const setFileValue = (el, el2, deleteButton) => {
    let text = el.value.match(/[\/\\]([\w\d\s\.\-\(\)]+)$/)[1];
    const errorMessage = el.parentNode.querySelector('.form-error-msg');
    el2.innerHTML = text;
    errorMessage.style.display = 'none';
    deleteButton.style.display = 'inline-block';
};

const clearSelectedFile = () => {
    fileInput.value = '';
    deleteButton.style.display = 'none';
    fileName.innerHTML = noFileSelectedText;
}

const fileInput = document.querySelector('.file-upload');
const customFileInput = document.querySelector('.custom-file-upload');
const deleteButton = document.querySelector('.remove-file');
const fileName = document.querySelector('.file-name');
customFileInput.addEventListener('click', function() {
    fileInput.click();
    clearSelectedFile();
});

fileInput.addEventListener('change', (input) => {
    setFileValue(input.target, fileName, deleteButton);
});

deleteButton.addEventListener('click', () => {
    clearSelectedFile();
});