const links = document.querySelectorAll(".js-scroll-to");

for (const link of links) {
  link.addEventListener("click", clickHandler);
}

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}

const modal = document.getElementById("popupModal");
const btn = document.getElementById("popupBtn");
const closeBtn = document.getElementsByClassName("close")[0];
const iframe = document.getElementById("popupFrame");

if(iframe) {
    const iframeSrc = iframe.src;
    
    btn.onclick = function() {
      modal.style.display = "flex";
      iframe.src = iframeSrc;
    }
    
    closeBtn.onclick = function() {
      modal.style.display = "none";
      iframe.src = '';
    }
}

const products = document.querySelectorAll(".product-item");
const closeModal = (productModal) => {
  productModal.style.display = "none";
}

if(products.length) {
  products.forEach(function(product) {
    const showModalButton = product.querySelector(".show-product-popup");
    const closeModalButton = product.querySelector(".close-product");
    const productModal = product.querySelector(".product-modal");
    const scrollToButton = product.querySelector(".js-scroll-to");
  
    showModalButton.addEventListener('click', function() {
      productModal.style.display = "flex";
    });
    
    closeModalButton.addEventListener('click', function() {
      closeModal(productModal);
    });

    productModal.addEventListener('click', function(e) {
      if(e.target == this) {
        closeModal(this)
      }
    });

    scrollToButton.addEventListener('click', function() {
      closeModal(productModal);
    });

    document.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        closeModal(productModal);
      }
    });
  });
}
